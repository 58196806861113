import Client1 from '../assets/img/Clients/grohn-logo.png';
import Client2 from '../assets/img/Clients/knauflogo.png';
import Client3 from '../assets/img/Clients/pci-logo.png';
import Client4 from '../assets/img/Clients/pergola-logo.png';
import Client5 from '../assets/img/Clients/texo-logo.png';
import Client6 from '../assets/img/Clients/wedi-logo.png';
import Client7 from '../assets/img/Clients/Brillux.png';
import Client8 from '../assets/img/Clients/isoplam.png';


const DataClients = [
    {
        id: 1,
        image: Client1
    },
    {
        id: 2,
        image: Client2
    },
    {
        id: 3,
        image: Client3
    },
    {
        id: 4,
        image: Client4
    },
    {
        id: 5,
        image: Client5
    },
    {
        id: 6,
        image: Client6
    },
    {
        id: 7,
        image: Client7
    },
    {
        id: 8,
        image: Client8
    },

]

export default DataClients;